<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 150px;">

			<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<a-row class="mt-0 pt-2 mb-20">

								<a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="current > 0" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col>

								<a-col :span="12" class="text-right pr-10">
									<!-- <a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button> -->
								</a-col>
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 5) * 100)" />
								</a-col>
							</a-row>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==0">
												<a-col :span="24" :md="18" :lg="18" :xl="{span: 18, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Let’s create a profile for your child</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorChildProfile.show"
														:message="errorChildProfile.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form-model
														class="login-form mt-20 pt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="12" :md="12" :lg="12">
																<a-form-item class="py-0 my-0" label="First Name" :colon="false">
																	<a-input
																		v-model="childProfile.firstName"
																		type="text" 
																		placeholder="First Name" />
																</a-form-item>
															</a-col>

															<a-col :span="24" :sm="12" :md="12" :lg="12">
																<a-form-item class="py-0 my-0" label="Last Name" :colon="false">
																	<a-input
																		v-model="childProfile.lastName"
																		type="text" 
																		placeholder="Last Name" />
																</a-form-item>
															</a-col>
														</a-row>

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="12" :md="12" :lg="12">
																<a-form-item 
																	class="py-0 my-0"
																	label="Gender"
																	placeholder="Select Gender"
																	:colon="false">
																	<a-radio-group v-model="childProfile.gender">
																		<a-radio :value="false">Male</a-radio>
																		<a-radio :value="true">Female</a-radio>
																	</a-radio-group>
																</a-form-item>
															</a-col>

															<!-- <a-col :span="24" :sm="12" :md="12" :lg="12">
																<a-form-item class="py-0 my-0" label="Date of Birth" :colon="false">
																	<a-date-picker
																	format="DD MMM YYYY"
																	style="width: 100%"
																	v-decorator="[
																		'dateOfBirth', 
																		{ rules: [] }
																	]"
																	placeholder="Birthday" />
																</a-form-item>
															</a-col> -->
														</a-row>
														
														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="childProfile.loading" 
																@click="submitChildProfile"
																v-if="childProfile.firstName != null && childProfile.lastName != null && childProfile.gender != null" type="primary" 
																style="width: 100px !important;" block class="login-form-button mt-10 text-white" >
																NEXT
															</a-button>
														</a-form-item>
														
													</a-form-model>

												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==1">
												<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which education system does {{ childProfile.gender ? 'she' : 'he' }} fit in?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorEducationSystem.show"
														:message="errorEducationSystem.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon/>

													<div class="mt-20"></div>

													<a-badge  
														v-for="option in educationSystems"
														:key="option.uuid"
														style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
														:count="`${option.name}`" 
														@click="handleEducationSystemSelect(option.uuid)"
														:number-style="educationSystem.uuid == option.uuid ? 
															{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
															{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
										

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="educationSystem.loading" v-if="educationSystem.uuid != null" @click="submitEductionSystem" type="primary" style="width: 100px !important;" block class="row login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
														
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==2">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which grade is {{ childProfile.gender ? 'she' : 'he' }} in?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorGrade.show"
														:message="errorGrade.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label=""
																	placeholder="Select Education Level"
																	:colon="false">
																	<a-select placeholder="Select Education Level" 
																		v-decorator="[ 'educationLevel',
																			{ rules: [{ required: true, message: 'Please select your education level!' }] },]" @change="onSelectEducationLevel">
																		<a-select-option v-for="option in educationLevels"
																			:value="option.uuid"
																			:key="option.name">
																			{{ option.name }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>



														<a-row :gutter="[24,24]" v-if="!grade.loading">
															<a-col :span="24" class="mt-20">
																<a-badge  
																	v-for="option in grades"
																	:key="option.uuid"
																	style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																	:count="`${option.name}`" 
																	@click="handleGradeSelect(option.uuid)"
																	:number-style="grade.uuid == option.uuid ? 
																		{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																		{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
															</a-col>
														</a-row>

														<div v-if="grade.loading">

															<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

																<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																	<a-spin class="text-primary" />

																</a-col>

															</a-row>
														</div>

														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button :loading="grade.loading" @click="submitGrades" v-if="grade.uuid" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==3">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which subjects does {{ childProfile.gender ? 'she' : 'he' }} need help with?</strong>  
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorSubject.show"
														:message="errorSubject.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row :gutter="[24,24]" v-if="!subject.loading">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in subjects"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleSubjectSelect(option.uuid)"
																:number-style="subject.uuids != null && subject.uuids.includes(option.uuid) ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<div v-if="subject.loading">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="subject.loading" v-if="subject.uuids != null && subject.uuids.length > 0" type="primary" style="width: 100px !important;" block @click="submitSubjects" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
													
												</a-col>
											</a-row>
		
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>


		</a-row>


		<a-modal v-model="skipConfirmation.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-size: 42px;" class="text-center">✋🏽</h6>
					<p style="font-size: 16px; font-weight: 500;">
						Providing your details helps you discover classes, Tutors & study materials that perfectly 
						match your learning goals. Are you sure you want to skip this step?
					</p>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20">
				<a-col :span="24" class="text-center">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="toHomePage">
						Skip
					</a-button>
					&nbsp;&nbsp;
					<a-button type="primary" class="text-white txtBtn" style="" @click="cancelModal">
						Stay on the page
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>
		
	</div>
</template>

<script>
	import VueCountryCode from "vue-country-code-select";
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
	
				current: 0,
				steps: [
					{
						title: 'Child Profile',
						content: 'First-content',
					},
					{
						title: 'Education System',
						content: 'Second-content',
					},
					{
						title: 'Grades',
						content: 'Third-content',
					},
					{
						title: 'Subjects',
						content: 'Fourth-content',
					},
				],

				genders: [
					{ uuid: false, name: "Male" },
					{ uuid: true, name: "Female" },
				],

				user: {},

				child: {},

				grades: [],
				subjects: [],
				educationLevels: [],
				educationSystems: [],
				
				childProfile: {
					firstName: null,
					lastName: null,
					gender: null,
					parent: null,
					password: "12345678",
					loading: false
				},

				errorChildProfile: {
					message: null,
					show: false,
				},

				educationSystem: {
					uuid: null,
					loading: false
				},
				errorEducationSystem: {
					message: null,
					show: false,
				},


				grade: {
					levelUuid: null,
					uuid: null,
					loading: false
				},
				errorGrade: {
					message: null,
					show: false,
				},


				subject: {
					uuids: [],
					loading: false
				},
				errorSubject: {
					message: null,
					show: false,
				},

				interesting: {
					uuid: null,
					isAcademic: null,
					loading: false
				},
				errorInteresting: {
					message: null,
					show: false,
				},

				skipConfirmation: {
					showModal: false
				},
				
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.childProfileForm = this.$form.createForm(this, { name: 'child_profile' });
			this.educationSystemForm = this.$form.createForm(this, { name: 'education_system_form' });
			this.gradeForm = this.$form.createForm(this, { name: 'grade_form' });
		},
		created() {
			this.getUserDetails();
			this.getEducationSystems();
		},
		methods: {

			nextScreen() {
				if(this.current == 3) {
					this.notify("You have successfully finish onboarding", 'success');
					this.$router.push({ path: '/home' }).then(() => { this.$router.go() })
				}else{
					this.current++;
				}
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},

			skipScreen() {
				this.skipConfirmation.showModal = true;
			},

			cancelModel() {
				this.skipConfirmation.showModal = false;
			},

			toHomePage() {
				this.$router.push({ path: '/home' }).then(() => { this.$router.go() })
			},


			handleLearningSelection(isAcademic) {
				this.learning.isAcademic = isAcademic;
			},

			handleClose() {
				this.error.show = false;
			},

			async getUserDetails() {
				let userDetails = JSON.parse(await localStorage.getItem('user_details'))

				this.user = userDetails
			},

			async getEducationSystems() {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/systems?page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.educationSystems = response.data.data;
				
				}).catch(error => {
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getEducationLevels(educationSystemUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/levels?educationSystem=${educationSystemUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.educationLevels = response.data.data;

				}).catch(error => {
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getGrades(levelUuid) {

				this.grade.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/grades?educationLevel=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.grades = response.data.data;

					this.grade.loading = false;

				}).catch(error => {

					this.grade.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getSubjects() {

				this.subject.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/subjects?educationLevels=${this.grade.levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.subjects = response.data.data;

					this.subject.loading = false;

				}).catch(error => {

					this.subject.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},



			submitChildProfile() {
				this.childProfile.loading = true;

				let url = `${this.$BACKEND_URL}/users`;

				this.childProfile.parent = this.user.uuid

				this.$AXIOS.post(url, this.childProfile).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						let {token, ...user_details} = response.data.data

						this.child = user_details;
						
						this.nextScreen();
						
					}
					this.childProfile.loading = false;
				}).catch((err) => {
					console.log(err)
					this.childProfile.loading = false;
					
					this.errorChildProfile.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorChildProfile.show = true
				});
			},

			handleEducationSystemSelect(uuid) {
				this.educationSystem.uuid = uuid;
			},

			async submitEductionSystem() {
				this.getEducationLevels(this.educationSystem.uuid)

				this.educationSystem.loading = true;

				let url = `${this.$BACKEND_URL}/profiles`;

				this.$AXIOS.post(url, {uuid: this.child.uuid, educationSystems: [this.educationSystem.uuid]}).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.educationSystem.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationSystem.loading = false;
					
					this.errorEducationSystem.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorEducationSystem.show = true
				});
			},

			onSelectEducationLevel(event) {
				this.grades = [];

				this.grade.uuid = null;	
				this.grade.levelUuid = event;
				
				this.getGrades(event)
			},

			handleGradeSelect(uuid) {
				this.grade.uuid = uuid;
			},


			submitGrades() {
				
				this.grade.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.child.uuid}`;

				let payload = {
					educationLevels: [this.grade.levelUuid],
					educationGrades: [this.grade.uuid]
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.getSubjects();

						this.nextScreen();
						
					}
					this.grade.loading = false;
				}).catch((err) => {
					console.log(err)
					this.grade.loading = false;
					
					this.errorGrade.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorGrade.show = true
				});
			},

			handleSubjectSelect(uuid) {
				console.log(uuid)
				if(this.subject.uuids != null && this.subject.uuids.includes(uuid)) {
					this.subject.uuids.splice(this.subject.uuids.indexOf(uuid), 1);
				}else{
					this.subject.uuids.push(uuid)
				}
			},

			submitSubjects() {
				this.subject.loading = true;

				let url = `${this.$BACKEND_URL}/profiles/${this.child.uuid}`;

				let payload = {
					subjects: this.subject.uuids,
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.subject.loading = false;
				}).catch((err) => {
					console.log(err)
					this.subject.loading = false;
					
					this.errorGrade.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorGrade.show = true
				});
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}

#divId {
	display: inline-block !important;
}
</style>
